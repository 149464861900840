<template>
  <div>
    <FiltriEconomatoPersonale
      v-if="fromInsegnante"
      @getEconomatoList="getEconomatoList"
      :id_persona="id_persona"
    />
    <TableEconomatoPersonale
      @getEconomatoList="getEconomatoList"
      :id_persona="id_persona"
      :fromInsegnante="fromInsegnante"
    />
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  computed,
  watch,
  getCurrentInstance,
} from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { ref } from "vue";
import { useRoute } from "vue-router";
import TableEconomatoPersonale from "@/components/components-fit/SNM/accesso-insegnante/TableEconomatoPersonale.vue";
import FiltriEconomatoPersonale from "@/components/components-fit/SNM/accesso-insegnante/FiltriEconomatoPersonale.vue";
import isEnabled from "@/composables/isEnabled.js";

export default defineComponent({
  name: "economato-personale",
  components: {
    TableEconomatoPersonale,
    FiltriEconomatoPersonale,
  },
  props: {
    fromInsegnante: {
      type: Boolean,
    },
  },
  setup(props) {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Area Riservata");
    });

    const store = useStore();

    const route = useRoute();
    const id_persona = ref(route.params.id_persona);

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const anno = computed(() => store.getters.stagioneSelected);

    const rowsToSkip = computed(() => store.getters.rowsToSkipEconomatoSnm);
    const fetchRows = computed(() => store.getters.fetchRowsEconomatoSnm);
    const sortColumn = computed(() => store.getters.sortColumnEconomatoSnm);
    const sortOrder = computed(() => store.getters.sortOrderEconomatoSnm);

    const tutteStagioniEconomatoSnm = computed(
      () => store.getters.tutteStagioniEconomatoSnm
    );

    const getEconomatoList = () => {
      const localKeys = {
        stagione: tutteStagioniEconomatoSnm.value ? null : anno.value,
        id_Persona: id_persona.value,
        rowstoskip: rowsToSkip.value,
        fetchrows: fetchRows.value,
        sortcolumn: sortColumn.value,
        sortorder: sortOrder.value,
      };
      store.dispatch("setStoreListData", {
        keys: localKeys,
        apiLink: globalApi.ECONOMATO_SNM_LIST,
        itemName: "economato_snm_list",
      });
    };

    getEconomatoList();

    if (!props.fromInsegnante) {
      watch(anno, () => {
        getEconomatoList();
      });
    }

    return {
      getEconomatoList,
      id_persona,
    };
  },
});
</script>
